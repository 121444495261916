// Project dependencies
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { usePastDelay } from 'react-lazy-no-flicker';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { AuthenticationProvider, AuthenticationSecure } from '@oliasoft/pkce';
import WithRuntimeConfig from './hocs/withRuntimeConfig.tsx';
import { getConfig } from './constants';
import MainRoutesContainer from './layout/MainRoutesContainer';
import '~translation/i18n';
import { history, store } from './reduxStore';
import './styles/new-global.less';
import '@oliasoft-open-source/react-ui-library/dist/global.css';

function Loading() {
  const past_delay = usePastDelay();
  if (!past_delay) { return null; }
  return <div>Loading ...</div>;
}

const App = () => {
  const config = getConfig();
  return (
    <AuthenticationProvider
      history={history}
      idpServerUrl={window.__runtimeConfigStatic.authentication_server_base_url}
      clientId={window.__runtimeConfigStatic.client_id}
      hostBaseUrl={config.hostBaseUrl}
      userManagerSettings={{
        monitorSession: false,
        automaticSilentRenew: true,
      }}
    >
      <ConnectedRouter history={history}>
        <AuthenticationSecure>
          <Switch>
            <MainRoutesContainer store={store} />
          </Switch>
        </AuthenticationSecure>
      </ConnectedRouter>
    </AuthenticationProvider>
  );
};

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <WithRuntimeConfig>
      <Provider store={store}>
        <App />
      </Provider>
    </WithRuntimeConfig>
  </Suspense>,
  document.getElementById('tlcont'));
